import { ColDef } from 'ag-grid-community'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import DateRangeFilter from '@shared/ag-grid/pagination-table/filters/date-range-filter/DateRangeFilter'
import PaginationTable from '@shared/ag-grid/pagination-table/PaginationTable'
import { Transaction } from '@shared/api/src/schemas/types'
import {
  ActiveStatus,
  HasPermission,
  UserNotAllowed,
  AccountType,
} from '@shared/components'
import AppTheme from '@shared/design'
import {
  useEnforceLogin,
  useFilterModel,
  useFtpPortalHubCommunication,
  useLocations,
} from '@shared/hooks'
import {
  codeToReason,
  filterAccountTypeValues,
  reasonDescriptionFilterOptions,
  filterMerchantAccount,
} from '@shared/mapping/gateway-transactions'
import { EnumServiceName } from '@shared/types'
import {
  currency,
  DataSource,
  formatDatetime,
  sortMerchantAccountsOnGridFilter,
} from '@shared/utils'

const useStyles = tss
  .withName('RecurringBillingDeclines')
  .create(({ theme }) => ({
    inactive: {
      marginRight: '5px',
    },
  }))

export default function RecurringBillingDeclines() {
  const { t } = useTranslation()

  const { classes } = useStyles(AppTheme)
  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const [selectedDate, setSelectedDate] = useState<string>(null)
  const { allMerchantAccounts, selectedLocation } = useLocations()

  const { user } = useEnforceLogin()
  const RecurringBillingDeclinesReportPrivs = [
    'v2.reports.get',
    'v2.transactions.get',
    'v2.recurrings.get',
  ]

  const extraParamsToExport = {
    status_code: '301',
    is_recurring: true,
  }

  useEffect(() => {
    setAppBarTitle(t('merchant-portal.recurring-billing-declines'), null, [
      t('common.reporting'),
      t('merchant-portal.gateway-reports'),
    ])
  }, [])

  const datasource = useMemo(() => {
    return new DataSource('transactions', {
      filterVariant: 'filter',
      fixedFilters: {
        location_id: selectedLocation?.id,
        status_code: '301',
        is_recurring: 'true',
      },
    })
  }, [selectedLocation?.id])

  const merchantAccountsOptions = allMerchantAccounts.filter(
    ({ payment_method }) => payment_method !== 'cash'
  )

  const columnDefs: ColDef<Transaction>[] = useMemo(() => {
    if (selectedLocation?.id && merchantAccountsOptions?.length > 0) {
      return [
        {
          headerName: t('common.account-type'),
          field: 'account_type',
          filter: 'agSetColumnFilter',
          floatingFilter: true,
          filterParams: {
            values: filterAccountTypeValues.map((option) => option.value),
            valueFormatter: (params) => {
              const option = filterAccountTypeValues.find(
                (option) => option.value === params.value
              )
              return option ? option.label : params.value
            },
          },
          cellRenderer: (data) => {
            return <AccountType type={data.value} />
          },
          sortable: true,
        },
        {
          headerName: t('common.token-last-four'),
          field: 'last_four',
          filterParams: {
            numberParser: (value: number) => value, // Needed to prevent removing leading zeros
          },
          floatingFilter: true,
          filter: 'agTextColumnFilter',
          sortable: true,
        },
        {
          headerName: t('common.date-transaction'),
          field: 'created_ts',
          floatingFilter: true,
          filter: DateRangeFilter,
          valueGetter: (params) => {
            const timestamp = params.data?.created_ts
            return formatDatetime(timestamp, user?.tz) || '-'
          },
          filterParams: {
            type: 'past',
            showTimePicker: true,
            allowNoFilter: false,
          },
          sortable: true,
          sort: 'desc',
          sortIndex: 0,
        },
        {
          headerName: t('common.amount-transaction'),
          field: 'transaction_amount',
          type: 'rightAligned',
          floatingFilter: true,
          filter: 'agNumberColumnFilter',
          filterParams: {
            allowedCharPattern: '\\d\\-\\.\\$\\,',
            numberParser: (value: number) => {
              return value ? value * 100 : null
            },
          },
          sortable: true,
          valueGetter: (params) =>
            currency(params.data?.transaction_amount / 100),
        },
        {
          headerName: `${t('common.recurring-billing')} ${t('common.title')}`,
          field: 'description',
          floatingFilter: true,
          filter: 'agTextColumnFilter',
          sortable: true,
        },
        {
          headerName: t('common.reason-description'),
          field: 'reason_code_id',
          floatingFilter: true,
          filter: 'agSetColumnFilter',
          filterParams: {
            values: reasonDescriptionFilterOptions.map(
              (option) => option.value
            ),
            valueFormatter: (params) => {
              const option = reasonDescriptionFilterOptions.find(
                (option) => option.value === params.value
              )
              return option ? option.label : params.value
            },
            comparator: (a, b) => {
              const aOption = reasonDescriptionFilterOptions.find(
                (option) => option.value === a
              )

              const bOption = reasonDescriptionFilterOptions.find(
                (option) => option.value === b
              )

              return aOption?.label.localeCompare(bOption?.label)
            },
          },
          valueGetter: (params) => {
            return codeToReason(params.data?.reason_code_id.toString())
          },
        },
        {
          headerName: t('common.merchant-account'),
          field: 'product_transaction_id',
          filter: 'agSetColumnFilter',
          filterParams: {
            values: filterMerchantAccount(merchantAccountsOptions).map(
              (option) => option.value
            ),
            valueFormatter: (params) => {
              const option = filterMerchantAccount(
                merchantAccountsOptions
              ).find((option) => option.value === params.value)
              return option ? option.label : params.value
            },
            comparator: (a, b) =>
              sortMerchantAccountsOnGridFilter(a, b, merchantAccountsOptions),
          },
          floatingFilter: true,
          valueGetter: (params) => {
            return params.data?.product_transaction.title
          },
          cellRenderer: (data) => {
            const merchantTitle = data.data?.product_transaction?.title
            const active = data.data?.product_transaction?.active
            return (
              <>
                <span className={classes.inactive}>{merchantTitle}</span>

                {!active && <ActiveStatus active={false} />}
              </>
            )
          },
          sortable: true,
        },
      ]
    }
    return []
  }, [selectedLocation?.id, merchantAccountsOptions?.length])

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
    }),
    []
  )

  const defaultFilters = {
    created_ts: {
      type: 'equal',
      filterType: 'text',
      filter: 'today',
    },
  }

  const paramKeys = [
    {
      queryKey: 'filter[created_ts][$gte]',
      filterKey: 'created_ts',
      filterType: 'greaterThanOrEqual',
      includeTime: true,
    },
  ]

  const initialFilterModel = useFilterModel(defaultFilters, paramKeys)

  return (
    <section>
      <HasPermission
        allPermissions={RecurringBillingDeclinesReportPrivs}
        unauthorizedComponent={<UserNotAllowed />}
      >
        <>
          {merchantAccountsOptions?.length > 0 && (
            <PaginationTable<Transaction>
              getRowId={({ data }) => data?.id}
              columnDefs={columnDefs}
              rowModelType={'serverSide'}
              serverSideDatasource={datasource}
              defaultColDef={defaultColDef}
              showExportButton={true}
              showClearFiltersButton={true}
              serviceName={EnumServiceName.RecurringBillingDeclinesReports}
              extraParamsToExport={extraParamsToExport}
              initialFilterModel={initialFilterModel}
              guidingId="reports-gateway-recurringbillingdeclines"
            />
          )}
        </>
      </HasPermission>
    </section>
  )
}
