import { ColDef } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useLocation } from 'react-router-dom'

import DateRangeFilter from '@shared/ag-grid/pagination-table/filters/date-range-filter/DateRangeFilter'
import PaginationTable from '@shared/ag-grid/pagination-table/PaginationTable'
import { Tags, Transaction } from '@shared/api/src/schemas/types'
import {
  HasPermission,
  UserNotAllowed,
  AccountType,
  TransactionDetailsModal,
  TagList,
} from '@shared/components'
import {
  useEnforceLogin,
  useFtpPortalHubCommunication,
  useLocations,
} from '@shared/hooks'
import {
  mapTransactionTypeId,
  filterTransactionTypeValues,
  filterAccountTypeValues,
} from '@shared/mapping/gateway-transactions'
import { EnumServiceName } from '@shared/types'
import { formatDatetime, currency, DataSource } from '@shared/utils'

export default function BatchDetails() {
  //TODO: add translation when email receipt or other functionality is added
  const { t } = useTranslation()
  const { id } = useParams()
  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const { locationTags, selectedLocation } = useLocations()
  const { state } = useLocation()
  const [selectedMerchantAccountID, setSelectedMerchantAccountID] =
    useState<string>()

  const { user } = useEnforceLogin()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [transactionId, setTransactionId] = useState<string>()

  const gridRef = useRef<AgGridReact>(null)

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setTransactionId(undefined)
    setIsModalOpen(false)
  }

  const TransactionsReportPrivs = [
    'v2.reports.get',
    'v2.transactionbatches.get',
  ]

  const extraParamsToExport = {
    transaction_batch_id: id,
    status_code: '101,111',
    product_transaction_id: selectedMerchantAccountID,
  }
  interface BatchDetailsState {
    merchantAccountID?: string
  }

  useEffect(() => {
    setAppBarTitle(
      t('merchant-portal.batch-details'),
      null,
      [
        t('common.reporting'),
        t('merchant-portal.gateway-reports'),
        t('merchant-portal.batches'),
      ],
      state?.backUrl || '/merchant/reports/gateway/batches'
    )
  }, [])

  const getGridRef = useCallback((ref) => {
    gridRef.current = ref
  }, [])

  const datasource = useMemo(() => {
    return new DataSource('transactions', {
      fixedFilters: {
        transaction_batch_id: id,
        status_code: ['101', '111'],
        product_transaction_id: selectedMerchantAccountID,
      },
      filterVariant: 'filter',
    })
  }, [id, selectedMerchantAccountID])

  useEffect(() => {
    if (!state) return
    setSelectedMerchantAccountID((state as BatchDetailsState).merchantAccountID)
  }, [state])

  const showOrWaitTags = () => {
    if (
      selectedLocation?.tags?.length > 0 &&
      !!locationTags &&
      locationTags?.length > 0
    ) {
      return true
    } else if (selectedLocation?.tags?.length === 0) {
      return true
    } else {
      return false
    }
  }

  const columnDefs: ColDef<Transaction>[] = useMemo(() => {
    if (showOrWaitTags()) {
      return [
        {
          headerName: t('common.date-transaction'),
          field: 'created_ts',
          floatingFilter: true,
          filter: DateRangeFilter,
          valueGetter: (params) => {
            const timestamp = params.data?.created_ts
            return formatDatetime(timestamp, user?.tz) || '-'
          },
          filterParams: {
            type: 'past',
            showTimePicker: true,
            allowNoFilter: false,
          },
          sortable: true,
        },
        {
          headerName: t('common.amount-transaction'),
          field: 'transaction_amount',
          type: 'rightAligned',
          floatingFilter: true,
          filter: 'agNumberColumnFilter',
          filterParams: {
            allowedCharPattern: '\\d\\-\\.\\$\\,',
            numberParser: (value: number) => {
              return value ? value * 100 : null
            },
          },
          sortable: true,
          valueGetter: (params) => {
            return currency(params.data?.transaction_amount / 100)
          },
        },
        {
          headerName: t('merchant-portal.batch-number'),
          field: 'batch',
          floatingFilter: true,
          filter: 'agNumberColumnFilter',
          sortable: true,
        },
        {
          headerName: t('common.transaction-type'),
          field: 'type_id',
          valueGetter: (params) => {
            return mapTransactionTypeId(params.data?.type_id)
          },
          filter: 'agSetColumnFilter',
          filterParams: {
            values: filterTransactionTypeValues.map((option) => option.value),
            valueFormatter: (params) => {
              const option = filterTransactionTypeValues.find(
                (option) => option.value === params.value
              )
              return option ? option.label : params.value
            },
            comparator: (a, b) => {
              const aOption = filterTransactionTypeValues.find(
                (option) => option.value === a
              )
              const bOption = filterTransactionTypeValues.find(
                (option) => option.value === b
              )

              return aOption.label.localeCompare(bOption.label)
            },
          },
          floatingFilter: true,
          sortable: true,
        },
        {
          headerName: t('common.account-type'),
          field: 'account_type',
          filter: 'agSetColumnFilter',
          floatingFilter: true,
          filterParams: {
            values: filterAccountTypeValues.map((option) => option.value),
            valueFormatter: (params) => {
              const option = filterAccountTypeValues.find(
                (option) => option.value === params.value
              )
              return option ? option.label : params.value
            },
          },
          cellRenderer: (data) => {
            return <AccountType type={data.value} />
          },
          sortable: true,
        },
        {
          headerName: t('common.token-last-four'),
          field: 'last_four',
          floatingFilter: true,
          filter: 'agNumberColumnFilter',
          filterParams: {
            numberParser: (value: number) => value, // Needed to prevent removing leading zeros
          },
          sortable: true,
        },
        {
          hide: selectedLocation?.tags?.length > 0 ? false : true,
          headerName: t('common.tags'),
          field: 'tags',
          floatingFilter: selectedLocation?.tags?.length > 0 ? true : false,
          filter: 'agSetColumnFilter',
          filterParams: {
            values: selectedLocation?.tags.map((tag) => tag.title),
            comparator: (a, b) => {
              return a.trim().localeCompare(b.trim())
            },
          },
          cellRenderer: (data) => {
            return (
              <TagList
                items={(data.value as Tags[]) ?? []}
                nameSelector={(tag: Tags) => tag.title}
                wrapWords={false}
              />
            )
          },
        },
      ]
    }
  }, [showOrWaitTags, selectedLocation])

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
    }),
    []
  )

  const handleRowClick = useCallback((event) => {
    setTransactionId(event.data.id)
    openModal()
  }, [])

  const refreshGrid = () => {
    gridRef.current!.api.refreshServerSide({ purge: true })
  }

  return (
    <section>
      <HasPermission
        allPermissions={TransactionsReportPrivs}
        unauthorizedComponent={<UserNotAllowed />}
      >
        <>
          {showOrWaitTags() && (
            <PaginationTable<Transaction>
              getRowId={({ data }) => data?.id}
              getGridRef={getGridRef}
              columnDefs={columnDefs}
              rowModelType={'serverSide'}
              serverSideDatasource={datasource}
              defaultColDef={defaultColDef}
              onRowClicked={handleRowClick}
              showExportButton={true}
              showClearFiltersButton={true}
              serviceName={EnumServiceName.BatchesDetails}
              extraParamsToExport={extraParamsToExport}
              guidingId="reports-gateway-batches-details"
            />
          )}
          {transactionId ? (
            <TransactionDetailsModal
              isModalOpen={isModalOpen}
              onClose={closeModal}
              transactionId={transactionId}
              tz={user.tz}
              onTransactionChange={refreshGrid}
            />
          ) : null}
        </>
      </HasPermission>
    </section>
  )
}
