import { api, MerchantAccount } from '@shared/api'
import { checkPermission, UserPermissionSet } from '@shared/utils'

export const getReportDownloadUrl = async (
  documentId: string,
  selectedMerchantAccount: MerchantAccount,
  userPermissionSet: UserPermissionSet
) => {
  if (
    !selectedMerchantAccount ||
    !checkPermission(userPermissionSet, 'v2.files.get')
  )
    return

  try {
    const res = await api
      .service('statements')
      .download(selectedMerchantAccount.id, documentId)

    if (res.url) {
      return res.url
    }
  } catch (error) {
    console.error('Error downloading statement', error)
  }
}
