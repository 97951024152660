export enum StatementType {
  SERVICES_STATEMENT = 'Services Statement',
  PROCESSING_STATEMENT = 'Processing Statement',
  SURCHARGE_SUPPLEMENTAL_STATEMENT = 'Surcharge Supplemental Statement',
}
export const statementTypeCodes: Record<number, StatementType> = {
  1: StatementType.SERVICES_STATEMENT,
  2: StatementType.PROCESSING_STATEMENT,
  3: StatementType.SURCHARGE_SUPPLEMENTAL_STATEMENT,
}
