import { LicenseManager } from 'ag-grid-enterprise'
import { useEffect, useState, useCallback, useMemo } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { I18nextProvider, useTranslation } from 'react-i18next'
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom'

import { ThemeProvider } from '@mui/material'

import packageJSON from '@/../package.json'

import AddressablePage from '@shared/addressable-page-manager/src/AddressablePage'
import AddressablePageManager from '@shared/addressable-page-manager/src/AddressablePageManager'
import { User, api } from '@shared/api/src'
import {
  ErrorHandler,
  InactivityModal,
  PasswordExpirationCheck,
} from '@shared/components'
import AppTheme from '@shared/design'
import {
  EnforceLoginProvider,
  AuthorizationProvider,
  FtpPortalHubCommunicationProvider,
  useFtpPortalHubCommunication,
  ReportFiltersProvider,
  NotificationProvider,
} from '@shared/hooks'
import { LocationProvider, useLocations } from '@shared/hooks/useLocations'
import {
  DashboardIcon,
  DocumentIcon,
  GatewayIcon,
  ReportsIcon,
  SettingsIcon,
  DepositsIcon,
  ChargebacksIcon,
} from '@shared/icons'
import { PaymentMethodType } from '@shared/types'
import {
  checkAllPermissions,
  checkSomePermissions,
  createUserPermissionSet,
  setupChatBotMerchantPortal,
  setupGoogleAnalytics,
  setupSentry,
} from '@shared/utils'

import MerchantFull from './assets/icons/MerchantFull'
import MerchantShort from './assets/icons/MerchantShort'
import i18n from './i18n'
import Chargebacks from './pages/chargebacks/ChargebacksGrid'
import Dashboard from './pages/dashboard/Dashboard'
import Deposits from './pages/deposits/Deposits'
import ACHRejects from './pages/reports/ach-rejects/ACHRejects'
import Batches from './pages/reports/gateway/batches/Batches'
import BatchDetails from './pages/reports/gateway/batches/details/BatchDetails'
import GatewayTransactions from './pages/reports/gateway/gateway-transactions/GatewayTransactions'
import RecurringBillingDeclines from './pages/reports/gateway/recurring-billing-declines/RecurringBillingDeclines'
import SettledTransactionsACH from './pages/reports/settled-transactions/settled-transactions-ach/SettledTransactionsACH'
import SettledTransactionsCC from './pages/reports/settled-transactions/settled-transactions-cc/SettledTransactionsCC'
import SettingsLocation from './pages/settings/location/SettingsLocation'
import MerchantAccountEdit from './pages/settings/merchant-accounts/merchant-account-edit/MerchantAccountEdit'
import MerchantAccountView from './pages/settings/merchant-accounts/merchant-account-view/MerchantAccountView'
import SettingsMerchantAccounts from './pages/settings/merchant-accounts/MerchantAccounts'
import Notifications from './pages/settings/notifications/Notifications'
import Statements from './pages/statements/Statements'

LicenseManager.setLicenseKey(process.env.AG_GRID_LICENSE)

export const LOCAL_STORAGE_SESSION_TOKEN_KEY = 'token'

const MerchantPortalApp = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [user, setUser] = useState<User | null>(null)
  const [addressablePageManager, setAddressablePageManager] =
    useState<AddressablePageManager>()
  const userPermissions = createUserPermissionSet(user)

  const hasSomePermissionGateway = checkSomePermissions(
    userPermissions,
    'v2.transactions.post',
    'v2.quickinvoices.get',
    'v2.recurrings.get',
    'v2.contacts.get'
  )
  const hasSomePermissionReportingGateway = checkSomePermissions(
    userPermissions,
    'v2.reports.get',
    'v2.transactions.get',
    'v2.transactionbatches.get',
    'v2.recurrings.get'
  )
  const hasSomePermissionReporting =
    checkSomePermissions(
      userPermissions,
      'v2.reports.get',
      'v2.transactions.get'
    ) || hasSomePermissionReportingGateway

  const hasSomePermissionSettings = checkSomePermissions(
    userPermissions,
    'v2.locationinfos.get',
    'v2.producttransactiondetails.get',
    'v2.userprefs.get',
    'v2.useruserprefs.get',
    'v2.quickinvoicesettings.get',
    'v2.productrecurrings.get',
    'v2.users.get'
  )

  const {
    selectedLocation,
    hasMerchantAccountACH,
    hasMerchantAccountCC,
    hasMerchantAccountCCActive,
    hasProductRecurringService,
    hasPaylinkEnabled,
    hasQuickInvoiceEnabled,
    allMerchantAccounts,
    setSelectedLocation,
  } = useLocations()

  const {
    setNavigatorMenu,
    setApplicationBanner,
    setPortalVersion,
    setAppShowSearchBar,
    setAppShowLocationBar,
  } = useFtpPortalHubCommunication()

  const loadPagesWithPermissions = useCallback(async () => {
    const allPages = [
      new AddressablePage(
        t('common.dashboard'),
        <Dashboard />,
        [],
        '/merchant/dashboard',
        <DashboardIcon />,
        false,
        true,
        'nav-merchant-dashboard'
      ),
      ////////////////////     GATEWAY (aka ftp-feature-mfe-gateway)    ////////////////////
      // Outlet components for routes because ftp-feature-mfe-gateway handles them on mono/packages/ftp-feature-mfe-gateway/src/FtpFeatureMfeGateway.tsx
      new AddressablePage(
        t('common.gateway'),
        <></>,
        [],
        undefined,
        <GatewayIcon />,
        false,
        hasSomePermissionGateway,
        'nav-merchant-gateway'
      )
        .addNestedPage(
          new AddressablePage(
            t('common.virtual-terminal'),
            <Outlet />,
            ['v2.transactions.post'],
            '/merchant/gateway/virtual-terminal',
            null,
            false,
            true,
            'nav-merchant-gateway-virtualterminal'
          )
        )
        .addNestedPage(
          new AddressablePage(
            t('common.quick-invoice'),
            <Outlet />,
            ['v2.quickinvoices.get'],
            '/merchant/gateway/quick-invoice',
            null,
            false,
            hasQuickInvoiceEnabled,
            'nav-merchant-gateway-quickinvoice'
          )
        )
        .addNestedPage(
          new AddressablePage(
            t('common.recurring-billing'),
            <Outlet />,
            ['v2.recurrings.get'],
            '/merchant/gateway/recurring-billing',
            null,
            false,
            hasProductRecurringService,
            'nav-merchant-gateway-recurringbilling'
          )
        )
        .addNestedPage(
          new AddressablePage(
            t('common.customers'),
            <Outlet />,
            ['v2.contacts.get'],
            '/merchant/gateway/customer',
            null,
            false,
            true,
            'nav-merchant-gateway-customer'
          )
        )
        .addNestedPage(
          new AddressablePage(
            t('common.paylinks'),
            <Outlet />,
            ['v2.paylinks.get'],
            '/merchant/gateway/paylinks',
            null,
            false,
            hasPaylinkEnabled,
            'nav-merchant-gateway-paylinks'
          )
        ),
      ////////////////////     REPORTS     ////////////////////
      new AddressablePage(
        t('common.reporting'),
        <></>,
        [],
        undefined,
        <ReportsIcon />,
        false,
        hasSomePermissionReporting,
        'nav-merchant-reporting'
      )
        .addNestedPage(
          new AddressablePage(
            t('merchant-portal.gateway-reports'),
            <></>,
            [],
            undefined,
            null,
            false,
            hasSomePermissionReportingGateway,
            'nav-merchant-reporting-gateway'
          )
            .addNestedPage(
              new AddressablePage(
                t('merchant-portal.batches'),
                <Batches />,
                ['v2.reports.get', 'v2.transactionbatches.get'],
                '/merchant/reports/gateway/batches',
                null,
                false,
                !!hasMerchantAccountCC,
                'nav-merchant-reporting-gateway-batches'
              )
            )
            .addNestedPage(
              new AddressablePage(
                t('common.transactions'),
                <GatewayTransactions />,
                ['v2.reports.get', 'v2.transactions.get'],
                '/merchant/reports/gateway/gateway-transactions',
                null,
                false,
                true,
                'nav-merchant-reporting-gateway-transactions'
              )
            )
            .addNestedPage(
              new AddressablePage(
                t('merchant-portal.recurring-billing-declines'),
                <RecurringBillingDeclines />,
                ['v2.reports.get', 'v2.transactions.get', 'v2.recurrings.get'],
                '/merchant/reports/gateway/recurring-billing-declines',
                null,
                false,
                !!hasMerchantAccountCC && !!hasProductRecurringService,
                'nav-merchant-reporting-gateway-recurringbillingdeclines'
              )
            )
        )
        .addNestedPage(
          new AddressablePage(
            t('common.settled-transactions-ach'),
            <SettledTransactionsACH />,
            ['v2.reports.get', 'v2.transactions.get'],
            '/merchant/reports/settled-transactions-ach',
            null,
            false,
            !!hasMerchantAccountACH,
            'nav-merchant-reporting-settledtransactionsach'
          )
        )
        .addNestedPage(
          new AddressablePage(
            t('common.settled-transactions-cc'),
            <SettledTransactionsCC />,
            ['v2.reports.get', 'v2.transactions.get'],
            '/merchant/reports/settled-transactions-cc',
            null,
            false,
            !!hasMerchantAccountCC,
            'nav-merchant-reporting-settledtransactionscc'
          )
        )
        .addNestedPage(
          new AddressablePage(
            t('merchant-portal.ach-rejects'),
            <ACHRejects />,
            ['v2.reports.get', 'v2.transactions.get'],
            '/merchant/reports/ach-rejects',
            null,
            false,
            allMerchantAccounts.some(
              (merchantAccount) =>
                merchantAccount.payment_method === PaymentMethodType.ACH &&
                merchantAccount.active
            ),
            'nav-merchant-reporting-achrejects'
          )
        ),
      new AddressablePage(
        t('merchant-portal.batch-details'),
        <BatchDetails />,
        [],
        '/merchant/reports/gateway/batches/:id/details',
        undefined,
        true
      ),
      ////////////////////     DEPOSITS     ////////////////////
      new AddressablePage(
        t('merchant-portal.deposits'),
        <Deposits />,
        ['v2.reports.get'],
        '/merchant/deposits',
        <DepositsIcon />,
        false,
        true,
        'nav-merchant-deposits'
      ),
      ////////////////////     STATEMENTS     ////////////////////
      new AddressablePage(
        t('common.statements'),
        <Statements />,
        ['v2.billingstatements.get'],
        '/merchant/statements',
        <DocumentIcon />,
        false,
        true,
        'nav-merchant-statements'
      ),
      ////////////////////     CHARGEBACKS     ////////////////////
      new AddressablePage(
        t('common.chargebacks'),
        <Chargebacks />,
        ['v2.reports.get', 'v2.transactions.get'],
        '/merchant/chargebacks',
        <ChargebacksIcon />,
        false,
        hasMerchantAccountCCActive,
        'nav-merchant-chargebacks'
      ),
      ////////////////////     SETTINGS     ////////////////////
      new AddressablePage(
        t('common.settings'),
        <></>,
        [],
        undefined,
        <SettingsIcon />,
        false,
        hasSomePermissionSettings,
        'nav-merchant-settings'
      )
        .addNestedPage(
          new AddressablePage(
            t('common.location'),
            <SettingsLocation />,
            ['v2.locationinfos.get'],
            '/merchant/settings/location',
            null,
            false,
            true,
            'nav-merchant-settings-location'
          )
        )
        .addNestedPage(
          new AddressablePage(
            t('common.merchant-accounts'),
            <SettingsMerchantAccounts />,
            ['v2.producttransactiondetails.get'],
            '/merchant/settings/merchant-accounts',
            null,
            false,
            true,
            'nav-merchant-settings-merchantaccounts'
          )
        )
        .addNestedPage(
          new AddressablePage(
            t('merchant-portal.view-merchant-account-cc'),
            <MerchantAccountView />,
            ['v2.producttransactiondetails.get'],
            '/merchant/settings/merchant-accounts/:id/view',
            undefined,
            true
          )
        )
        .addNestedPage(
          new AddressablePage(
            t('merchant-portal.edit-merchant-account'),
            <MerchantAccountEdit />,
            ['v2.producttransactiondetails.get'],
            '/merchant/settings/merchant-accounts/:id/edit',
            undefined,
            true
          )
        )

        .addNestedPage(
          new AddressablePage(
            t('common.notifications'),
            <Notifications />,
            [
              'v2.userprefs.get',
              'v2.useruserprefs.get',
              'v2.quickinvoicesettings.get',
              'v2.productrecurrings.get',
            ],
            '/merchant/settings/notifications',
            null,
            false,
            true,
            'nav-merchant-settings-notifications'
          )
        )
        .addNestedPage(
          new AddressablePage(
            t('common.users-management'),
            <Outlet />,
            ['v2.users.get'],
            '/merchant/user-management',
            null,
            false,
            true,
            'nav-merchant-settings-usersmanagement'
          )
        )
        .addNestedPage(
          new AddressablePage(
            t('common.token-import'),
            <Outlet />,
            ['v2admin.tokenimports.get'],
            '/merchant/token-import',
            null,
            false,
            true,
            'nav-merchant-settings-tokenimport'
          )
        ),
    ]
    return allPages.filter((page) => {
      return checkAllPermissions(userPermissions, ...page.permissions)
    })
  }, [
    userPermissions,
    hasSomePermissionSettings,
    hasMerchantAccountACH,
    hasMerchantAccountCC,
    hasMerchantAccountCCActive,
    hasProductRecurringService,
    hasPaylinkEnabled,
    hasQuickInvoiceEnabled,
    hasSomePermissionGateway,
    hasSomePermissionReporting,
    hasSomePermissionReportingGateway,
  ])

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const fetchedUser = await getUser()
        setUser(fetchedUser)
      } catch (error) {
        console.error('ERROR GETTING/SETTING USER DATA:', error.toString())
      }
    }

    fetchUser()
    setPortalVersion({ version: packageJSON?.version })
    setAppShowSearchBar(false)
    setAppShowLocationBar(true)
    setApplicationBanner({
      expanded: MerchantFull,
      collapsed: MerchantShort,
    })
    // Load 3rd party plugins
    // GOOGLE ANALYTICS
    if (process.env.REACT_GA_TRACKING) {
      setupGoogleAnalytics()
    }
    // SENTRY
    if (process.env.SENTRY_DNS) {
      setupSentry()
    }
    // CHATBOT
    if (process.env.CHATBOT_MERCHANT_ID) {
      setupChatBotMerchantPortal()
    }
  }, [])

  useEffect(() => {
    async function fetchPages() {
      const pages = await loadPagesWithPermissions()
      if (!user || !pages) return
      setAddressablePageManager(new AddressablePageManager(pages, user))
    }

    fetchPages()
  }, [user, selectedLocation.id])

  useEffect(() => {
    if (!addressablePageManager) return
    setNavigatorMenu({
      menuItems: addressablePageManager.generateAllNavigatorMenuItems(),
    })
  }, [addressablePageManager])

  async function getUser() {
    const user = await api.service('users').authorize()

    setUser(user)
    return user
  }

  useEffect(() => {
    if (!addressablePageManager || !userPermissions) return

    const handleLocationChange = async (event) => {
      await setSelectedLocation(event.detail.location)

      const newRoute = addressablePageManager.handleLocationChange(
        window.location.pathname
      )

      newRoute ? navigate(newRoute) : null
    }

    window.addEventListener(
      'FTP_NAVIGATOR_LOCATION_CHANGED',
      handleLocationChange
    )

    return () => {
      window.removeEventListener(
        'FTP_NAVIGATOR_LOCATION_CHANGED',
        handleLocationChange
      )
    }
  }, [addressablePageManager])

  if (!addressablePageManager) return null

  return (
    <AuthorizationProvider user={user}>
      <ThemeProvider theme={AppTheme}>
        <Routes>
          {addressablePageManager.renderAllAddressablePages()}
          <Route
            path="/merchant"
            element={<Navigate to="/merchant/dashboard" />}
          />
          <Route path="/merchant/gateway/*" element={<Outlet />} />
          <Route path="/merchant/token-import/*" element={<Outlet />} />
          <Route path="/merchant/userprofile/*" element={<Outlet />} />
          <Route path="/merchant/user-management/*" element={<Outlet />} />
        </Routes>
        <InactivityModal />
        <PasswordExpirationCheck />
      </ThemeProvider>
    </AuthorizationProvider>
  )
}

const MerchantPortal: React.FC = () => {
  return (
    <FtpPortalHubCommunicationProvider>
      <ErrorBoundary FallbackComponent={ErrorHandler}>
        <EnforceLoginProvider redirectToLogin>
          <I18nextProvider i18n={i18n}>
            <BrowserRouter>
              <ReportFiltersProvider>
                <LocationProvider>
                  <NotificationProvider>
                    <MerchantPortalApp />
                  </NotificationProvider>
                </LocationProvider>
              </ReportFiltersProvider>
            </BrowserRouter>
          </I18nextProvider>
        </EnforceLoginProvider>
      </ErrorBoundary>
    </FtpPortalHubCommunicationProvider>
  )
}

export default MerchantPortal
