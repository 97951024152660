import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Box, Typography } from '@mui/material'

import {
  Checkbox,
  FieldGroupContainer,
  FieldGroupRow,
} from '@shared/components'

const useStyles = tss
  .withName('NotificationPreference')
  .create(({ theme }) => ({
    title: {
      color: theme.palette['neutral-900'],
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '20px',
      display: 'flex',
    },
    description: {
      color: theme.palette['neutral-500'],
      fontFamily: 'Inter',
      fontSize: '14px',
      lineHeight: '20px',
      marginBottom: '4px',
    },
  }))

interface NotificationPreferenceProps {
  title: string
  description?: string
  value?: string | number
  emailChecked?: boolean
  smsChecked?: boolean
  id?: string
  isEdit?: boolean
  onChange?: (value: string, type: string) => void
  hideSms?: boolean
  style?: React.CSSProperties
  guidingId?: string
}

export default function NotificationPreference({
  title,
  description,
  value,
  emailChecked = false,
  smsChecked = false,
  id = '',
  isEdit = false,
  onChange,
  hideSms = true,
  style,
  guidingId,
}: NotificationPreferenceProps) {
  const { t } = useTranslation()
  const { classes } = useStyles()

  return (
    <FieldGroupContainer style={style} guidingId={`${guidingId}-preference`}>
      <FieldGroupRow>
        <Box sx={{ flex: 1, paddingTop: '14px' }}>
          <Typography className={classes.title}>
            {title}
            {value ? (
              <Typography
                className={classes.description}
                sx={{ marginLeft: '10px' }}
              >
                {value}
              </Typography>
            ) : null}
          </Typography>
          {description ? (
            <Typography className={classes.description}>
              {description}
            </Typography>
          ) : null}
        </Box>
        {!value ? (
          <Box sx={{ display: 'flex', marginLeft: '16px' }}>
            <Checkbox
              checked={emailChecked}
              disabled={!isEdit}
              onChange={() => {
                onChange(id, 'email')
              }}
              label={t('common.email')}
              guidingId={`${guidingId}-email`}
            />

            {!hideSms && (
              <Checkbox
                checked={smsChecked}
                disabled={!isEdit}
                onChange={() => {
                  onChange(id, 'sms')
                }}
                label={t('SMS')}
                guidingId={`${guidingId}-sms`}
              />
            )}
          </Box>
        ) : null}
      </FieldGroupRow>
    </FieldGroupContainer>
  )
}
