import React, { useEffect, useState } from 'react'
import { tss } from 'tss-react/mui'

import { Grid, Typography } from '@mui/material'

import { MerchantAccount } from '@shared/api'
import { ActiveStatus } from '@shared/components'
import appTheme from '@shared/design'

const useStyles = tss
  .withName('MerchantAccountCashToggle')
  .create(({ theme }) => ({
    title: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '28px',
      color: theme.palette['neutral-700'],
      marginRight: '1rem',
    },
  }))

interface MerchantAccountCashToggleProps {
  merchantAccount: MerchantAccount
}

const MerchantAccountCashToggle = ({
  merchantAccount,
}: MerchantAccountCashToggleProps) => {
  const { classes } = useStyles(appTheme)
  const [enabled, setEnabled] = useState(false)

  useEffect(() => {
    if (!merchantAccount) return
    setEnabled(!!merchantAccount.active)
  }, [merchantAccount])

  return (
    <Grid container>
      <Grid
        item
        xs={11}
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '16px',
        }}
      >
        <Typography className={classes.title}>
          {merchantAccount.title}
        </Typography>
        <ActiveStatus active={merchantAccount.active} />
      </Grid>
    </Grid>
  )
}

export default MerchantAccountCashToggle
