import { ColDef } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import DateRangeFilter from '@shared/ag-grid/pagination-table/filters/date-range-filter/DateRangeFilter'
import RadioFilter from '@shared/ag-grid/pagination-table/filters/radio-filter/RadioFilter'
import PaginationTable from '@shared/ag-grid/pagination-table/PaginationTable'
import { Tags, Transaction } from '@shared/api/src/schemas/types'
import {
  ActiveStatus,
  HasPermission,
  TransactionStatus,
  UserNotAllowed,
  AccountType,
  TagList,
  TransactionDetailsModal,
} from '@shared/components'
import AppTheme from '@shared/design'
import {
  useEnforceLogin,
  useFtpPortalHubCommunication,
  useLocations,
  useFilterModel,
} from '@shared/hooks'
import {
  mapTransactionTypeId,
  codeToReason,
  codeToEntryMode,
  codeToSource,
  filterTransactionTypeValues,
  filterTransactionStatusValues,
  filterAccountTypeValues,
  reasonDescriptionFilterOptions,
  entryModeFilterOptions,
  trxSourceFilterOtions,
  secCodeFilterOptions,
  filterPaymentMethod,
  filterAvsResponse,
  filterMerchantAccount,
} from '@shared/mapping/gateway-transactions'
import {
  GatewayTransactionEntryModeKey,
  EnumServiceName,
  TransactionSourceCode,
} from '@shared/types'
import {
  formatDatetime,
  formatPhoneNumber,
  currency,
  filterParams,
  DataSource,
  sortMerchantAccountsOnGridFilter,
} from '@shared/utils'

const useStyles = tss.withName('GatewayTransactions').create(({ theme }) => ({
  inactive: {
    marginRight: '5px',
  },
}))

export default function GatewayTransactions() {
  //TODO: add translation when email receipt or other functionality is added
  const { t } = useTranslation()
  const { classes } = useStyles(AppTheme)
  const { allMerchantAccounts, locationTags, selectedLocation } = useLocations()
  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const { user } = useEnforceLogin()
  const TransactionsReportPrivs = ['v2.reports.get', 'v2.transactions.get']
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [transactionId, setTransactionId] = useState<string>()

  const gridRef = useRef<AgGridReact>(null)

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setTransactionId(undefined)
    setIsModalOpen(false)
  }

  useEffect(() => {
    setAppBarTitle(t('merchant-portal.gateway-transactions'), null, [
      t('common.reporting'),
      t('merchant-portal.gateway-reports'),
    ])
  }, [])

  const datasource = useMemo(() => {
    return new DataSource('transactions', {
      filterVariant: 'filter',
      fixedFilters: {
        location_id: selectedLocation?.id,
      },
    })
  }, [selectedLocation?.id])

  const getGridRef = useCallback((ref) => {
    gridRef.current = ref
  }, [])

  const columnDefs: ColDef<Transaction>[] = useMemo(() => {
    return [
      {
        headerName: t('common.amount-transaction'),
        field: 'transaction_amount',
        type: 'rightAligned',
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
        filterParams: {
          allowedCharPattern: '\\d\\-\\.\\$\\,',
          numberParser: (value: number) => {
            return value ? value * 100 : null
          },
        },
        sortable: true,
        valueGetter: (params) =>
          currency(params.data?.transaction_amount / 100),
      },
      {
        headerName: t('common.date-transaction'),
        field: 'created_ts',
        floatingFilter: true,
        filter: DateRangeFilter,
        valueGetter: (params) => {
          const timestamp = params.data?.created_ts
          return formatDatetime(timestamp, user?.tz) || '-'
        },
        filterParams: {
          type: 'past',
          allowNoFilter: false,
          showTimePicker: true,
        },
        sortable: true,
        sort: 'desc',
      },
      {
        headerName: t('merchant-portal.batch-number'),
        field: 'batch',
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
        sortable: true,
      },
      {
        headerName: t('common.account-holder-name'),
        field: 'account_holder_name',
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
      },
      {
        headerName: t('common.transaction-type'),
        field: 'type_id',
        valueGetter: (params) => {
          return mapTransactionTypeId(params.data?.type_id)
        },
        filter: 'agSetColumnFilter',
        filterParams: {
          values: filterTransactionTypeValues.map((option) => option.value),
          valueFormatter: (params) => {
            const option = filterTransactionTypeValues.find(
              (option) => option.value === params.value
            )
            return option ? option.label : params.value
          },
          comparator: (a, b) => {
            const aOption = filterTransactionTypeValues.find(
              (option) => option.value === a
            )
            const bOption = filterTransactionTypeValues.find(
              (option) => option.value === b
            )

            return aOption.label.localeCompare(bOption.label)
          },
        },
        floatingFilter: true,
        sortable: true,
      },
      {
        headerName: t('common.status'),
        field: 'status_code',
        floatingFilter: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: filterTransactionStatusValues.map((option) => option.value), // Send values to API
          valueFormatter: (params) => {
            const option = filterTransactionStatusValues.find(
              (option) => option.value === params.value
            )
            return option ? option.label : params.value
          },
          comparator: (a, b) => {
            const aOption = filterTransactionStatusValues.find(
              (option) => option.value === a
            )
            const bOption = filterTransactionStatusValues.find(
              (option) => option.value === b
            )

            return aOption.label.localeCompare(bOption.label)
          },
        },
        cellRenderer: (data) => <TransactionStatus statusCode={data.value} />,
        sortable: true,
      },
      {
        headerName: t('common.account-type'),
        field: 'account_type',
        filter: 'agSetColumnFilter',
        floatingFilter: true,
        filterParams: {
          values: filterAccountTypeValues.map((option) => option.value),
          valueFormatter: (params) => {
            const option = filterAccountTypeValues.find(
              (option) => option.value === params.value
            )
            return option ? option.label : params.value
          },
        },
        cellRenderer: (data) => {
          return <AccountType type={data.value} />
        },
        sortable: true,
      },
      {
        headerName: t('common.token-last-four'),
        field: 'last_four',
        floatingFilter: true,
        filterParams: {
          numberParser: (value: number) => value, // Needed to prevent removing leading zeros
        },
        filter: 'agNumberColumnFilter',
        sortable: true,
      },
      {
        headerName: t('common.merchant-account'),
        field: 'product_transaction_id',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: filterMerchantAccount(allMerchantAccounts).map(
            (option) => option.value
          ),
          valueFormatter: (params) => {
            const option = filterMerchantAccount(allMerchantAccounts).find(
              (option) => option.value === params.value
            )
            return option ? option.label : params.value
          },
          comparator: (a, b) =>
            sortMerchantAccountsOnGridFilter(a, b, allMerchantAccounts),
        },
        floatingFilter: true,
        valueGetter: (params) => {
          return params.data?.product_transaction?.title
        },
        cellRenderer: (data) => {
          const merchantTitle = data.data?.product_transaction?.title
          const active = data.data?.product_transaction?.active
          return (
            <>
              <span className={classes.inactive}>{merchantTitle}</span>

              {!active && <ActiveStatus active={false} />}
            </>
          )
        },
        sortable: true,
      },
      {
        headerName: t('common.reason-description'),
        field: 'reason_code_id',
        floatingFilter: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: reasonDescriptionFilterOptions.map((option) => option.value),
          valueFormatter: (params) => {
            const option = reasonDescriptionFilterOptions.find(
              (option) => option.value === params.value
            )
            return option ? option.label : params.value
          },
          comparator: (a, b) => {
            const aOption = reasonDescriptionFilterOptions.find(
              (option) => option.value === a
            )
            const bOption = reasonDescriptionFilterOptions.find(
              (option) => option.value === b
            )

            return aOption.label.localeCompare(bOption.label)
          },
        },
        valueGetter: (params) => {
          return codeToReason(params.data?.reason_code_id.toString())
        },
        sortable: true,
      },
      {
        headerName: `${t('common.customer')} ${t('common.name-first')}`,
        field: 'contact.first_name',
      },
      {
        headerName: `${t('common.customer')} ${t('common.name-last')}`,
        field: 'contact.last_name',
      },
      {
        headerName: t('merchant-portal.is-recurring'),
        field: 'is_recurring',
        floatingFilter: true,
        cellRenderer: (data) => {
          return data.value ? t('common.yes') : t('common.no')
        },
        filter: RadioFilter,
        filterParams: {
          defaultTo: 'all',
          ignoreSelectAll: true,
        },
        sortable: true,
      },
      {
        headerName: t('merchant-portal.is-wallet'),
        field: 'is_token',
        floatingFilter: true,
        cellRenderer: (data) => {
          return data.value ? t('common.yes') : t('common.no')
        },
        filter: RadioFilter,
        filterParams: {
          defaultTo: 'all',
          ignoreSelectAll: true,
        },
        sortable: true,
      },
      {
        headerName: t('common.payment-method'),
        field: 'payment_method',
        floatingFilter: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: filterPaymentMethod.map((option) => option.value),
          valueFormatter: (params) => {
            const option = filterPaymentMethod.find(
              (option) => option.value === params.value
            )
            return option ? option.label : params.value
          },
        },
        sortable: true,
      },
      {
        headerName: `${t('common.quick-invoice')} ${t('common.title')}`,
        field: 'quick_invoice.title',
      },
      {
        headerName: t('common.avs-response'),
        field: 'avs',
        floatingFilter: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: filterAvsResponse.map((option) => option.value),
          valueFormatter: (params) => {
            const option = filterAvsResponse.find(
              (option) => option.value === params.value
            )
            return option ? option.label : params.value
          },
          comparator: (a, b) => {
            const aOption = filterAvsResponse.find(
              (option) => option.value === a
            )
            const bOption = filterAvsResponse.find(
              (option) => option.value === b
            )

            return aOption.label.localeCompare(bOption.label)
          },
        },
        sortable: true,
      },
      {
        headerName: t('common.street-billing'),
        field: 'billing_address.street',
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
      },
      {
        headerName: t('common.city-billing'),
        field: 'billing_address.city',
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
      },
      {
        headerName: t('common.state-billing'),
        field: 'billing_address.state',
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
      },
      {
        headerName: t('common.zip-code-billing'),
        field: 'billing_address.postal_code',
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
        sortable: true,
      },
      {
        headerName: t('common.phone-billing'),
        field: 'billing_address.phone',
        valueFormatter: function (params) {
          return params.data?.billing_address?.phone !== null
            ? formatPhoneNumber(params.data?.billing_address?.phone)
            : ''
        },
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
        filterParams: {
          textFormatter: (value) => formatPhoneNumber(value),
        },
        sortable: true,
      },
      {
        headerName: t('merchant-portal.currency-code'),
        field: 'currency_code',
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
        sortable: true,
      },
      {
        headerName: t('common.custom-1'),
        field: 'transaction_c1',
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
      },
      {
        headerName: t('common.custom-2'),
        field: 'transaction_c2',
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
      },
      {
        headerName: t('common.custom-3'),
        field: 'transaction_c3',
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
      },
      {
        headerName: t('common.description'),
        field: 'description',
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
      },
      {
        headerName: t('common.entry-mode'),
        field: 'entry_mode_id',
        floatingFilter: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: entryModeFilterOptions.map((option) => option.value),
          valueFormatter: (params) => {
            const option = entryModeFilterOptions.find(
              (option) => option.value === params.value
            )
            return option ? option.label : params.value
          },
          comparator: (a, b) => {
            const aOption = entryModeFilterOptions.find(
              (option) => option.value === a
            )
            const bOption = entryModeFilterOptions.find(
              (option) => option.value === b
            )

            return aOption.label.localeCompare(bOption.label)
          },
        },
        valueGetter: (params) => {
          return codeToEntryMode(
            params.data?.entry_mode_id as GatewayTransactionEntryModeKey
          )
        },
        sortable: true,
      },
      {
        headerName: t('common.token-first-six'),
        field: 'first_six',
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
        filterParams: {
          numberParser: (value: number) => value, // Needed to prevent removing leading zeros
        },
        sortable: true,
      },
      {
        headerName: t('common.order-number'),
        field: 'order_number',
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
        sortable: true,
      },
      {
        headerName: t('merchant-portal.payment-source'),
        field: 'trx_source_code',
        floatingFilter: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: trxSourceFilterOtions.map((option) => option.value),
          valueFormatter: (params) => {
            const option = trxSourceFilterOtions.find(
              (option) => option.value === params.value
            )
            return option ? option.label : params.value
          },
          comparator: (a, b) => {
            const aOption = trxSourceFilterOtions.find(
              (option) => option.value === a
            )
            const bOption = trxSourceFilterOtions.find(
              (option) => option.value === b
            )

            return aOption.label.localeCompare(bOption.label)
          },
        },
        valueGetter: (params) => {
          return codeToSource(
            params.data?.trx_source_code?.toString() as TransactionSourceCode
          )
        },
        sortable: true,
      },
      {
        headerName: t('merchant-portal.po-number'),
        field: 'po_number',
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
        sortable: true,
      },
      {
        headerName: t('common.amount-sales-tax'),
        field: 'tax',
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
        sortable: true,
      },
      {
        headerName: t('common.amount-surcharge'),
        field: 'surcharge.surcharge_amount',
        cellRenderer: (data) => {
          return data.value ? currency(data.value / 100) : ''
        },
      },
      {
        headerName: t('common.transaction-sec-code'),
        field: 'ach_sec_code',
        floatingFilter: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: secCodeFilterOptions.map((option) => option.value),
          valueFormatter: (params) => {
            const option = secCodeFilterOptions.find(
              (option) => option.value === params.value
            )
            return option ? option.label : params.value
          },
        },
        sortable: true,
      },
      {
        headerName: t('common.auth-code'),
        field: 'auth_code',
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
      },
      {
        headerName: t('common.tags'),
        field: 'tags',
        floatingFilter: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: locationTags ? locationTags.map((tag) => tag.title) : [],
          comparator: (a, b) => {
            return a.trim().localeCompare(b.trim())
          },
        },
        cellRenderer: (data) => {
          return (
            <TagList
              items={(data.value as Tags[]) ?? []}
              nameSelector={(tag: Tags) => tag.title}
              wrapWords={false}
            />
          )
        },
      },
    ]
  }, [allMerchantAccounts, locationTags])

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
    }),
    []
  )

  const showOrWaitTags = () => {
    if (
      selectedLocation &&
      selectedLocation?.tags?.length > 0 &&
      !!locationTags &&
      locationTags?.length > 0
    ) {
      return true
    } else if (selectedLocation?.tags?.length === 0) {
      return true
    } else {
      return false
    }
  }

  const defaultFilters = {
    created_ts: {
      type: 'equal',
      filterType: 'text',
      filter: 'today',
    },
  }

  const paramKeys = [
    {
      queryKey: 'filter[created_ts][$gte]',
      filterKey: 'created_ts',
      filterType: 'greaterThanOrEqual',
      includeTime: true,
    },
    {
      queryKey: 'filter[type_id]',
      filterKey: 'type_id',
      filterType: 'agSetColumnFilter',
      includeTime: false,
    },
    {
      queryKey: 'filter[status_code]',
      filterKey: 'status_code',
      filterType: 'agSetColumnFilter',
      includeTime: false,
    },
  ]

  const initialFilterModel = useFilterModel(defaultFilters, paramKeys)

  const handleRowClick = useCallback((event) => {
    setTransactionId(event.data.id)
    openModal()
  }, [])

  const refreshGrid = () => {
    gridRef.current!.api.refreshServerSide({ purge: true })
  }

  return (
    <section>
      <HasPermission
        allPermissions={TransactionsReportPrivs}
        unauthorizedComponent={<UserNotAllowed />}
      >
        {allMerchantAccounts?.length > 0 && showOrWaitTags() && (
          <>
            <PaginationTable<Transaction>
              getRowId={({ data }) => data?.id}
              getGridRef={getGridRef}
              columnDefs={columnDefs}
              rowModelType={'serverSide'}
              serverSideDatasource={datasource}
              defaultColDef={defaultColDef}
              onRowClicked={handleRowClick}
              showExportButton={true}
              showClearFiltersButton={true}
              serviceName={EnumServiceName.GatewayTransactionsReports}
              initialFilterModel={initialFilterModel}
              guidingId="reports-gateway-transactions"
            />
            {transactionId ? (
              <TransactionDetailsModal
                isModalOpen={isModalOpen}
                onClose={closeModal}
                transactionId={transactionId}
                tz={user.tz}
                onTransactionChange={refreshGrid}
              />
            ) : null}
          </>
        )}
      </HasPermission>
    </section>
  )
}
