import { omit } from 'lodash'
import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Typography } from '@mui/material'

import { MerchantAccount } from '@shared/api'
import { Checkbox, FieldGroupRow } from '@shared/components'

const useStyles = tss
  .withName('AllowedTransactionTypes')
  .create(({ theme }) => ({
    title: {
      color: theme.palette['neutral-700'],
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '20px',
      marginBottom: '16px',
    },
  }))

interface AllowedTransactionTypesProps {
  isEdit?: boolean
}

const AllowedTransactionTypes: FC<AllowedTransactionTypesProps> = ({
  isEdit = false,
}) => {
  const { classes } = useStyles()
  const { t } = useTranslation()

  const { control } = useFormContext<MerchantAccount>()

  return (
    <>
      <FieldGroupRow>
        <Typography className={classes.title}>
          {t(
            'merchant-portal.merchant-account-settings.allowed-transaction-types'
          )}
        </Typography>
      </FieldGroupRow>
      <FieldGroupRow>
        <Controller
          name="ach_allow_debit"
          control={control}
          render={({ field }) => (
            <Checkbox
              {...omit(field, 'ref')}
              checked={!!field.value}
              label={t('common.debit')}
              disabled={!isEdit}
              testId="ach-allow-debit-checkbox"
              guidingId="settings-merchantaccount-achallowdebit"
            />
          )}
        />
      </FieldGroupRow>
      <FieldGroupRow>
        <Controller
          name="ach_allow_credit"
          control={control}
          render={({ field }) => (
            <Checkbox
              {...omit(field, 'ref')}
              checked={!!field.value}
              label={t('merchant-portal.merchant-account-settings.credit')}
              disabled={!isEdit}
              testId="ach-allow-credit-checkbox"
              guidingId="settings-merchantaccount-achallowcredit"
            />
          )}
        />
      </FieldGroupRow>
      <FieldGroupRow>
        <Controller
          name="ach_allow_refund"
          control={control}
          render={({ field }) => (
            <Checkbox
              {...omit(field, 'ref')}
              checked={!!field.value}
              label={t('merchant-portal.merchant-account-settings.refund')}
              disabled={!isEdit}
              testId="ach-allow-refund-checkbox"
              guidingId="settings-merchantaccount-achallowrefund"
            />
          )}
        />
      </FieldGroupRow>
    </>
  )
}

export default AllowedTransactionTypes
