import { ColDef } from 'ag-grid-community'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import DisplayTable from '@shared/ag-grid/display-table/DisplayTable'
import { api } from '@shared/api'
import { DepositDetails } from '@shared/api/src/schemas/types'
import { ActionModal } from '@shared/components'
import { formatDate, currency } from '@shared/utils'

export interface DepositDetailsProps<T> {
  depositId: string
  locationId: string
  open?: boolean
  handleClose: (event: any) => void
}

const useStyles = tss.withName('DepositDetails').create(({ theme }) => ({
  modal: {
    width: '60vw',
    maxWidth: '60vw',
  },
}))

export default function DepositDetail({
  depositId,
  locationId,
  open,
  handleClose,
}: DepositDetailsProps<any>) {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const guidingId = 'deposits-details'

  const [details, setDetails] = useState<DepositDetails[]>()

  const getDetails = async () => {
    try {
      const results = await api.service('deposits-reports').get(depositId, {
        query: {
          filter: {
            location_id: locationId,
          },
        },
      })
      setDetails(results)
    } catch (error) {
      setDetails([])
    }
  }

  useEffect(() => {
    if (depositId) {
      getDetails()
    }
  }, [depositId])

  const columnDefs: ColDef<DepositDetails>[] = useMemo(
    () => [
      {
        headerName: t('common.date-reported'),
        field: 'date_reported_ts',
        valueGetter: (params) => {
          const timestamp = params.data?.date_reported_ts
          return timestamp ? formatDate(timestamp) : ''
        },
        suppressHeaderMenuButton: true,
      },
      {
        headerName: t('common.date-settled'),
        field: 'date_settled_ts',
        valueGetter: (params) => {
          const timestamp = params.data?.date_settled_ts
          return timestamp ? formatDate(timestamp) : ''
        },
        suppressHeaderMenuButton: true,
      },
      {
        headerName: t('common.memo'),
        field: 'memo',
        suppressHeaderMenuButton: true,
      },
      {
        headerName: t('common.mid'),
        field: 'mid',
        suppressHeaderMenuButton: true,
      },
      {
        headerName: t('common.reference-number'),
        field: 'reference_number',
        suppressHeaderMenuButton: true,
      },
      {
        headerName: t('common.transaction-type'),
        field: 'trxn_type',
        suppressHeaderMenuButton: true,
      },

      {
        headerName: t('common.amount'),
        field: 'amount',
        type: 'rightAligned',
        cellRenderer: (params) => {
          if (isNaN(params.data?.amount)) {
            return <b>{params.data?.amount}</b>
          } else {
            return currency(params.data?.amount / 100)
          }
        },
        suppressHeaderMenuButton: true,
      },
    ],
    [t]
  )

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
    }),
    []
  )

  const totalAmount = useMemo(() => {
    if (!details) return 0

    const amount = details.reduce((acc, curr) => {
      return acc + (curr?.amount || 0)
    }, 0)

    return amount
  }, [details])

  const totalsRowData = [
    {
      id: 'totalsRow',
      amount: `${t('common.total')}: ${currency(totalAmount / 100)}`,
    },
  ]

  return (
    <ActionModal
      open={open}
      onClose={handleClose}
      title={t('merchant-portal.deposit-details')}
      isScrollableContent
      className={classes.modal}
      guidingId={guidingId}
    >
      <section>
        <DisplayTable<DepositDetails>
          columnDefs={columnDefs}
          data={details || []}
          domLayout="autoHeight"
          defaultColDef={defaultColDef}
          pinnedBottomRowData={totalsRowData}
          autoSizeStrategy={{
            type: 'fitCellContents',
          }}
          guidingId={guidingId}
        />
      </section>
    </ActionModal>
  )
}
