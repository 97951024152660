import SvgIcon from '@mui/material/SvgIcon'
const Download = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.00008 11.3333V2"
          stroke="#374151"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M14 11.3333C14 12.806 12.806 14 11.3333 14H4.66667C3.194 14 2 12.806 2 11.3333"
          stroke="#374151"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.3333 8L7.99935 11.334L4.66602 8"
          stroke="#374151"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </SvgIcon>
  )
}
export default Download
