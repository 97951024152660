import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Typography } from '@mui/material'

import { MerchantAccount, api } from '@shared/api'
import { ActionModal, Button } from '@shared/components'
import { NotificationData, useNotification } from '@shared/hooks'
import { toArrayFieldErrors } from '@shared/utils'

const useStyles = tss
  .withName('MerchantDeactivateModal')
  .create(({ theme }) => ({
    text: {
      color: theme.palette['neutral-500'],
      fontFamily: 'Inter',
      fontSize: '14px',
      lineHeight: '20px',
      marginTop: '2px',
    },
    modalButtonContainer: {
      width: '48%',
    },
  }))

interface ToggleMerchantDeactivateModalProps {
  merchantAccount: MerchantAccount
  open: boolean
  handleClose: () => void
  onFinish: () => void
}
const ToggleMerchantDeactivateModal = ({
  merchantAccount,
  open,
  handleClose,
  onFinish,
}: ToggleMerchantDeactivateModalProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const { setNotification } = useNotification()

  const [loading, setLoading] = useState(false)

  const guidingId = 'settings-merchantaccounts-modal'

  const handleDeactivate = async () => {
    setLoading(true)
    try {
      await api.service('merchant-accounts').remove(merchantAccount.id)
      setNotification({
        type: 'success',
        label: t(
          'merchant-portal.merchant-account-settings.merchant-account-deactivated'
        ),
      })
      onFinish()
    } catch (error) {
      const errorsFormatted = toArrayFieldErrors(error)
      const message = errorsFormatted.length
        ? errorsFormatted[0].message
        : t(
            'merchant-portal.merchant-account-settings.error-deactivating-merchant-account'
          )
      setNotification({
        type: 'error',
        label: message,
      })
    } finally {
      setLoading(false)
      handleClose()
    }
  }

  const handleActivation = async () => {
    setLoading(true)
    try {
      await api.service('merchant-accounts').activate(merchantAccount.id)

      setNotification({
        type: 'success',
        label: t(
          'merchant-portal.merchant-account-settings.merchant-account-reactivated'
        ),
      })
      onFinish()
    } catch (error) {
      const errorsFormatted = toArrayFieldErrors(error)
      const message = errorsFormatted.length
        ? errorsFormatted[0].message
        : t(
            'merchant-portal.merchant-account-settings.error-reactivating-merchant-account'
          )

      setNotification({
        type: 'error',
        label: message,
      })
    } finally {
      setLoading(false)
      handleClose()
    }
  }

  return (
    <ActionModal
      open={open}
      onClose={handleClose}
      title={
        merchantAccount.active
          ? t(
              'merchant-portal.merchant-account-settings.deactivate-merchant-account'
            )
          : t(
              'merchant-portal.merchant-account-settings.reactivate-merchant-account'
            )
      }
      buttons={[
        {
          label: t('common.cancel'),
          onClick: handleClose,
          color: 'secondary',
          containerClassName: classes.modalButtonContainer,
          guidingId: `${guidingId}-cancel`,
        },
        {
          label: merchantAccount.active
            ? t('common.action.yes-deactivate')
            : t('common.action.yes-reactivate'),
          onClick: merchantAccount.active ? handleDeactivate : handleActivation,
          containerClassName: classes.modalButtonContainer,
          isLoading: loading,
          color: 'primary',
          guidingId: `${guidingId}-confirm`,
        },
      ]}
      guidingId={`${guidingId}-active`}
    >
      <Typography className={classes.text}>
        {`${
          merchantAccount.active
            ? t(
                'merchant-portal.merchant-account-settings.are-you-sure-you-want-to-deactivate'
              )
            : t(
                'merchant-portal.merchant-account-settings.are-you-sure-you-want-to-reactivate'
              )
        } `}
        <span
          style={{
            fontWeight: 'bold',
          }}
        >
          {merchantAccount?.title}
        </span>
        ?
      </Typography>
    </ActionModal>
  )
}

export default ToggleMerchantDeactivateModal
