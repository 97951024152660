import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { UserPref, UserPrefSubscribe } from '@shared/api/src'
import { FieldGroupContainer } from '@shared/components'

import EditMyNotifications from './components/EditMyNotifications'
import NotificationList from '../components/notification-list/NotificationList'

interface MyNotificationsProps {
  allPrefs: UserPref[]
  subscribedPrefs: UserPrefSubscribe[]
}

export default function MyNotifications({
  allPrefs,
  subscribedPrefs,
}: MyNotificationsProps) {
  const { t } = useTranslation()
  const [editMyNotification, setEditMyNotification] = useState(false)

  return (
    <>
      <FieldGroupContainer
        title={t('merchant-portal.notifications-settings.my-notifications')}
        buttonData={{
          label: t(
            'merchant-portal.notifications-settings.edit-my-notifications'
          ),
          color: 'secondary',
          onClick: () => setEditMyNotification(true),
          guidingId: 'settings-notifications-my-notifications-edit',
        }}
        description={t(
          'merchant-portal.notifications-settings.the-notifications-listed-on-this-page-are-specific-to-location-only'
        )}
        guidingId="settings-notifications-my-notifications"
      >
        <NotificationList
          prefs={allPrefs}
          subscriptions={subscribedPrefs.map((p) => p?.user_pref_id)}
        />
      </FieldGroupContainer>

      {editMyNotification && (
        <EditMyNotifications
          allPrefs={allPrefs}
          subscribedPrefs={subscribedPrefs}
          open={editMyNotification}
          handleClose={() => setEditMyNotification(false)}
        />
      )}
    </>
  )
}
